* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
input,
button,
textarea {
  font-family: "Nunito Sans", sans-serif;
}

#root {
  margin: auto;
  box-shadow: 0px 0px 4px rgba(52, 52, 122, 0.3);
}

header {
  width: 100%;
}
header .contacts-info {
  margin: 0 1rem;
  word-wrap: wrap;
  word-break: break-all;
  color: rgb(44, 44, 110);
  font-size: 11px;
  text-align: left;
  font-weight: 600;
}
header .contacts-info ul {
  list-style: none;
}
header .contacts-info i {
  margin-right: 0.5rem;
}
header .header-box {
  padding: 0.5rem 0;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  filter: drop-shadow(0 1.5rem 1.5rem rgba(5, 15, 105, 0.15));
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}
header .header-box a {
  color: #151C7E;
}
header .header-box .socials {
  color: rgb(44, 44, 110);
  display: flex;
  gap: 2rem;
}
header .header-top {
  width: 44%;
  display: flex;
  color: #151C7E;
  align-items: center;
}
header .header-top .menu-drawer {
  display: none;
}
header .header-top .menu {
  white-space: nowrap;
  display: flex;
  list-style: none;
  justify-content: space-around;
  gap: 2rem;
  padding: 0 1rem;
  font-size: 14px;
}
header .header-top .menu .menu-opt {
  text-decoration: none;
  font-weight: 600;
  transition: 0.3s;
}
header .header-top .menu .menu-opt:hover {
  color: rgb(189, 192, 241);
}
header .register-btn {
  border: 1px solid red;
  position: fixed;
  right: 10%;
  top: 3.5rem;
  z-index: 10;
  padding: 0.6rem 1.2rem;
  border-radius: 2rem;
  border: 3px solid rgb(255, 255, 255);
  background-color: #151C7E;
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.presentation {
  overflow-x: hidden;
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 5rem;
  /* Buttons */
}
.presentation .slideshow {
  margin: 0 auto;
  max-width: 100%;
}
.presentation .slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}
.presentation .slide {
  height: 100%;
  display: inline-block;
  width: 100%;
  border-radius: 40px;
}
.presentation .slide img {
  filter: drop-shadow(1rem 2rem 0.5rem rgba(0, 0, 0, 0.05));
  width: 100%;
  margin: auto;
  display: flex;
}
.presentation .slideshowDots {
  text-align: center;
}
.presentation .slideshowDot {
  display: inline-block;
  height: 0.6rem;
  width: 0.6rem;
  border-radius: 50%;
  cursor: pointer;
  margin: 15px 7px 0px;
  background-color: #c4c4c4;
}
.presentation .slideshowDot.active {
  background-color: #6a0dad;
}

.about-conponent {
  margin: 1rem 0 6rem 0;
}
.about-conponent h2 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #151C7E;
  padding-bottom: 1rem;
  border-bottom: 4px dotted #151c7e;
}
.about-conponent h3 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #151C7E;
  text-align: center;
  margin-top: 2rem !important;
}
.about-conponent p {
  text-align: left;
  margin: 1.5rem auto;
  max-width: 800px;
  line-height: 1.5;
  color: rgb(46, 34, 34);
  font-size: 14px;
}

.send-confirmation-page {
  padding: 3rem;
  height: 80.7vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.send-confirmation-page h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #151C7E;
  text-align: center;
}
.send-confirmation-page p {
  color: #151C7E;
  text-align: center;
  margin: 1rem 0;
  font-size: 1.2rem;
}

.contact-page .page-container,
.home-page .page-container,
.about-page .page-container,
.providers-page .page-container,
.products-page .page-container,
.not-found .page-container,
.register-page .page-container,
.send-confirmation-page .page-container {
  margin-top: 0rem;
  padding: 3rem;
  background-color: white;
}
.contact-page .page-container .product-presentation h2,
.home-page .page-container .product-presentation h2,
.about-page .page-container .product-presentation h2,
.providers-page .page-container .product-presentation h2,
.products-page .page-container .product-presentation h2,
.not-found .page-container .product-presentation h2,
.register-page .page-container .product-presentation h2,
.send-confirmation-page .page-container .product-presentation h2 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #151C7E;
}
.contact-page .page-container .product-presentation p,
.home-page .page-container .product-presentation p,
.about-page .page-container .product-presentation p,
.providers-page .page-container .product-presentation p,
.products-page .page-container .product-presentation p,
.not-found .page-container .product-presentation p,
.register-page .page-container .product-presentation p,
.send-confirmation-page .page-container .product-presentation p {
  padding: 0.3rem 0;
  margin: 1rem 0;
  font-size: 12px;
  border-top: 4px dotted #151C7E;
  color: rgb(190, 36, 31);
}
.contact-page .page-container .product-presentation .boxes-container,
.home-page .page-container .product-presentation .boxes-container,
.about-page .page-container .product-presentation .boxes-container,
.providers-page .page-container .product-presentation .boxes-container,
.products-page .page-container .product-presentation .boxes-container,
.not-found .page-container .product-presentation .boxes-container,
.register-page .page-container .product-presentation .boxes-container,
.send-confirmation-page .page-container .product-presentation .boxes-container {
  display: flex;
  overflow: hidden;
  overflow-x: scroll;
  background: linear-gradient(rgba(19, 28, 59, 0.3), rgba(238, 242, 255, 0.8)), url("./images/header.jpg");
  border-radius: 1rem;
  padding: 2rem;
}
.contact-page .page-container .product-presentation .boxes-container::-webkit-scrollbar,
.home-page .page-container .product-presentation .boxes-container::-webkit-scrollbar,
.about-page .page-container .product-presentation .boxes-container::-webkit-scrollbar,
.providers-page .page-container .product-presentation .boxes-container::-webkit-scrollbar,
.products-page .page-container .product-presentation .boxes-container::-webkit-scrollbar,
.not-found .page-container .product-presentation .boxes-container::-webkit-scrollbar,
.register-page .page-container .product-presentation .boxes-container::-webkit-scrollbar,
.send-confirmation-page .page-container .product-presentation .boxes-container::-webkit-scrollbar {
  background-color: transparent;
  height: 30px !important;
  border-radius: 4px;
}
.contact-page .page-container .product-presentation .boxes-container::-webkit-scrollbar-thumb,
.home-page .page-container .product-presentation .boxes-container::-webkit-scrollbar-thumb,
.about-page .page-container .product-presentation .boxes-container::-webkit-scrollbar-thumb,
.providers-page .page-container .product-presentation .boxes-container::-webkit-scrollbar-thumb,
.products-page .page-container .product-presentation .boxes-container::-webkit-scrollbar-thumb,
.not-found .page-container .product-presentation .boxes-container::-webkit-scrollbar-thumb,
.register-page .page-container .product-presentation .boxes-container::-webkit-scrollbar-thumb,
.send-confirmation-page .page-container .product-presentation .boxes-container::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgb(209, 49, 49);
}
.contact-page .page-container .product-presentation .box,
.home-page .page-container .product-presentation .box,
.about-page .page-container .product-presentation .box,
.providers-page .page-container .product-presentation .box,
.products-page .page-container .product-presentation .box,
.not-found .page-container .product-presentation .box,
.register-page .page-container .product-presentation .box,
.send-confirmation-page .page-container .product-presentation .box {
  min-width: 1000px;
  margin: 2rem 5%;
  padding: 0rem 0;
  background-color: rgb(249, 251, 255);
  display: flex;
  border-radius: 8px;
  align-items: center;
}
.contact-page .page-container .product-presentation .box .product,
.home-page .page-container .product-presentation .box .product,
.about-page .page-container .product-presentation .box .product,
.providers-page .page-container .product-presentation .box .product,
.products-page .page-container .product-presentation .box .product,
.not-found .page-container .product-presentation .box .product,
.register-page .page-container .product-presentation .box .product,
.send-confirmation-page .page-container .product-presentation .box .product {
  width: 9rem;
  height: 7rem;
  margin: 0.5rem auto;
  padding: 0.25rem;
}
.contact-page .page-container .product-presentation .box .box-left,
.home-page .page-container .product-presentation .box .box-left,
.about-page .page-container .product-presentation .box .box-left,
.providers-page .page-container .product-presentation .box .box-left,
.products-page .page-container .product-presentation .box .box-left,
.not-found .page-container .product-presentation .box .box-left,
.register-page .page-container .product-presentation .box .box-left,
.send-confirmation-page .page-container .product-presentation .box .box-left {
  display: flex;
  padding: 1rem;
  flex-basis: 30%;
}
.contact-page .page-container .product-presentation .box .box-left .provider,
.home-page .page-container .product-presentation .box .box-left .provider,
.about-page .page-container .product-presentation .box .box-left .provider,
.providers-page .page-container .product-presentation .box .box-left .provider,
.products-page .page-container .product-presentation .box .box-left .provider,
.not-found .page-container .product-presentation .box .box-left .provider,
.register-page .page-container .product-presentation .box .box-left .provider,
.send-confirmation-page .page-container .product-presentation .box .box-left .provider {
  margin: auto;
  width: 15rem;
  border-radius: 4px;
}
.contact-page .page-container .product-presentation .box .box-right,
.home-page .page-container .product-presentation .box .box-right,
.about-page .page-container .product-presentation .box .box-right,
.providers-page .page-container .product-presentation .box .box-right,
.products-page .page-container .product-presentation .box .box-right,
.not-found .page-container .product-presentation .box .box-right,
.register-page .page-container .product-presentation .box .box-right,
.send-confirmation-page .page-container .product-presentation .box .box-right {
  border-radius: 1rem;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  margin: 2rem;
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 0rem;
  flex-basis: 70%;
}

.home-page .page-container {
  margin-top: 0;
}
.home-page .page-container .category-list {
  margin: 0;
}

.providers-presentation {
  margin: 2rem 0;
}
.providers-presentation h2 {
  margin: 1.2rem 0;
  color: #151C7E;
  font-weight: 600;
  font-size: 1.2rem;
}
.providers-presentation p {
  padding: 0.5rem 0;
  border-top: 4px dotted #151C7E;
  margin: 1rem 0 0 0;
  color: rgb(192, 41, 41);
  font-size: 12px;
}

#main-slider-container {
  width: 100%;
  max-height: 300px;
  margin: 2rem auto;
}
#main-slider-container svg {
  z-index: 9;
}

.slider-card {
  pointer-events: none;
  width: 216px;
  height: 240px;
  background: linear-gradient(45deg, #2d37c0, #151C7E);
  border-radius: 4px;
  display: inline-block;
  margin: 1rem 0;
  margin-left: 1rem;
  margin-right: 1rem;
  box-shadow: 2px 2px 2px 2px rgba(14, 45, 112, 0.25);
  filter: drop-shadow(1rem 2rem 3px rgba(0, 0, 0, 0.07));
  cursor: pointer;
}
.slider-card p {
  font-weight: 700;
  border: none;
  opacity: 1;
  word-wrap: break-word !important;
  color: white;
}

.slider-card-image {
  max-height: 120px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background-color: rgb(255, 255, 255);
  background-position: center;
  background-size: 60% !important;
  background-repeat: no-repeat;
}

.slider-card-description {
  max-width: 100%;
  text-align: center;
  margin: 5px 0px 5px 10px;
}

.slider-card-title {
  font-size: 14px !important;
  text-align: center;
  font-weight: 600;
  color: white;
}

.two-column-container {
  display: flex;
  gap: 2rem;
  justify-content: space-evenly;
  margin: 2rem 0;
  padding: 2rem 0;
  flex-wrap: wrap;
}

.location-contact h2 {
  margin: 1.2rem 0;
  color: #151C7E;
  font-size: 1.2rem;
  font-weight: 600;
}
.location-contact p {
  padding: 0.5rem 0;
  border-top: 4px dotted #151C7E;
  margin: 1rem 0 0 0;
  color: rgb(192, 41, 41);
  font-size: 12px;
}

.not-found {
  height: 70vh;
  max-width: 30rem;
  margin: auto;
  margin-top: 3rem;
  color: #151C7E;
  text-align: center;
}
.not-found .page-container {
  margin-top: 3rem;
}
.not-found h1 {
  font-size: 8rem;
  font-weight: 400;
}
.not-found h3 {
  margin-top: -3rem;
  text-align: right;
  font-size: 2rem;
  font-weight: 500;
}

.contact-form {
  padding: 0.5rem 2rem;
  margin: auto;
  box-sizing: border-box;
  background: linear-gradient(rgba(13, 28, 80, 0.6), rgba(12, 30, 92, 0.9)), url("./images/header.jpg");
}
.contact-form h2 {
  color: rgb(12, 30, 92);
  margin: 2rem 0;
  font-weight: 600;
  font-size: 1.2rem;
}
.contact-form .location {
  width: 32vw;
  min-width: 20rem;
  height: 100%;
  border-radius: 0.5rem;
  border: none;
}
.contact-form form {
  padding: 2rem 0;
  min-width: 20rem;
  min-height: 28rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.99));
  width: 32vw;
  height: 100%;
  border-radius: 0.5rem;
}
.contact-form form .contact-form-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-form form .sended-message {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.contact-form form .two-column-input {
  width: 80%;
  display: flex;
}
.contact-form form label {
  width: 80%;
  padding: 0.3rem 0;
  font-size: 12px;
  font-weight: 600;
  color: rgb(12, 30, 92);
  margin: 0.3rem;
}
.contact-form form label input,
.contact-form form label textarea {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  resize: none;
  width: 100%;
  border-radius: 0.2rem;
  border: none;
  padding: 0.6rem;
  outline: none;
  margin: 0.2rem auto;
}
.contact-form form button {
  padding: 0.6rem 3rem;
  border: none;
  background: linear-gradient(45deg, #2d37c0, #151C7E);
  border-radius: 0.2rem;
  color: white;
  transition: 0.1s;
}
.contact-form form button:disabled {
  opacity: 0.3;
}
.contact-form form button:hover {
  background-color: rgb(45, 67, 148);
}

.about-images {
  margin: 3rem 0;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}
.about-images img {
  min-width: 30rem;
  border: 4px solid white;
  filter: drop-shadow(4px 4px 3px rgb(228, 228, 228));
  border-radius: 0.8rem;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.7);
  margin: 0rem -1rem;
  width: 20%;
}

.products-page {
  width: 80%;
  padding: 3rem 0;
  margin: auto;
}
.products-page h2 {
  font-size: 1.2rem;
  padding: 0 3rem;
  margin-top: 3rem;
  font-weight: 600;
  color: #151C7E;
}

.category-list {
  margin: 0 0 3rem 0;
  width: 100%;
  padding: 3rem;
  padding-top: 1rem;
  flex-wrap: wrap;
  display: flex;
  gap: 3rem;
  text-align: center;
}
.category-list .category-card {
  transition: 0.3s;
  background-color: white;
  margin: auto;
  border: 4px solid white;
  border-radius: 0.3rem;
  filter: drop-shadow(0.5rem 0.5rem 2px rgba(21, 28, 126, 0.1843137255));
  width: 10rem;
  height: 13rem;
}
.category-list .category-card:hover {
  border: 2px solid rgba(21, 28, 126, 0.0470588235);
  transform: translateY(-0.7rem);
  filter: drop-shadow(1.5rem 1.5rem 5px rgba(21, 28, 126, 0.137254902));
}
.category-list .category-card img {
  width: 100%;
}
.category-list .category-card h3 {
  margin: 1rem 0;
  color: #151C7E;
  font-weight: 600;
  font-size: 1rem;
}
.category-list .category-card button {
  padding: 0.4rem 0.7rem;
  border: none;
  background: linear-gradient(45deg, #2d37c0, #151C7E);
  border-radius: 0.2rem;
}
.category-list .category-card button a {
  font-weight: 600;
  text-decoration: none;
  color: white;
}

footer {
  position: relative;
  bottom: -1.1rem;
  width: 100%;
  color: white;
  background: linear-gradient(to right, rgb(42, 80, 184), rgb(5, 35, 88));
  padding: 3rem 0.5rem;
  min-height: 24vh;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: flex-end;
  justify-content: space-evenly;
}
footer .copyright {
  display: flex;
  flex-direction: column;
  opacity: 0.6;
  justify-content: flex-end;
  font-size: 11px;
  text-align: center;
}
footer ul {
  color: white;
  font-weight: 700;
  list-style-type: none;
}
footer ul i {
  margin: 0.3rem 1rem;
}

.register-page {
  margin: 2rem auto;
  max-width: 80%;
  margin-top: 5rem;
}
.register-page h2 {
  font-size: 1.2rem;
  color: #151C7E;
}
.register-page h3 {
  color: #151C7E;
  font-size: 14px;
}
.register-page p {
  font-size: 12px;
  color: rgb(124, 38, 38);
}

.register-form {
  filter: drop-shadow(1rem 1rem 4px rgba(21, 28, 126, 0.2117647059));
  margin: 2rem auto;
  max-width: 700px;
  border: 2px solid rgba(21, 28, 126, 0.1215686275);
  border-radius: 0.3rem;
  background-color: white;
  padding: 2rem;
}
.register-form h3 {
  margin-top: 0 !important;
  text-align: left !important;
}
.register-form input {
  width: 100% !important;
}
.register-form h2 {
  color: white;
  font-size: 1rem;
  text-align: center;
  background-color: #151C7E;
}
.register-form h3 {
  text-align: center;
  font-size: 14px;
  color: #151C7E;
  margin-top: 1rem;
}
.register-form .action {
  border: 2px solid rgba(21, 28, 126, 0.1215686275);
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}
.register-form .action label {
  gap: 1rem;
  flex-direction: row;
}
.register-form .two-column-input {
  padding: 0.3rem;
  border: 4px dotted rgba(15, 21, 112, 0.0823529412);
  border-radius: 0.3rem;
  display: flex;
}
.register-form .two-column-input label {
  font-size: 12px;
  font-weight: 600;
  color: #151C7E;
  padding: 0.2rem;
  flex-basis: 50%;
}
.register-form .two-column-input label input {
  outline: none;
  padding: 0.2rem 0.3rem;
  border: 2px solid rgba(12, 20, 138, 0.1647058824);
  border-radius: 0.2rem;
  width: 100%;
  font-size: 13px;
}
.register-form button {
  display: flex;
  font-weight: 600;
  padding: 0.5rem 1.5rem;
  border-radius: 0.4rem;
  margin: auto;
  margin-top: 1rem;
  border: none;
  background: linear-gradient(45deg, #2d37c0, #151C7E);
  color: white;
  box-shadow: 4px 4px 3px rgba(0, 0, 0, 0.1);
  border: 2px solid white;
}
.register-form button:disabled {
  opacity: 0.3;
  pointer-events: none;
}
.register-form label {
  font-size: 12px;
  font-weight: 600;
  color: #151C7E;
  margin: 0.5rem 0;
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
}
.register-form label input {
  font-size: 13px;
  outline: none;
  padding: 0.3rem;
  border: 2px solid rgba(12, 20, 138, 0.1647058824);
  border-radius: 0.2rem;
  width: 50%;
}

.providers-page .loading {
  margin: auto;
}
.providers-page h2 {
  text-align: center;
  color: #151C7E;
  font-weight: 600;
  font-size: 1.2rem;
}
.providers-page .search-box {
  padding: 0.2rem;
  margin: 2rem auto;
  min-width: 20rem;
  max-width: 30rem;
  background-color: #151c7e;
  text-align: center;
  border-radius: 0.2rem;
}
.providers-page .search-box input {
  padding: 0.3rem;
  border-radius: 0.2rem;
  border: none;
  outline: none;
  width: 90%;
}
.providers-page .search-box button {
  border: none;
  background: none;
  color: white;
  width: 10%;
  transition: 0.1s;
}
.providers-page .search-box button:disabled {
  opacity: 0.2;
}
.providers-page .search-result-container {
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 2rem;
}
.providers-page .search-result-container .provider-card {
  background-color: white;
  border-radius: 1rem;
  filter: drop-shadow(0px 0px 2px rgba(15, 19, 82, 0.3137254902)) !important;
  margin: 2rem auto;
}
.providers-page .page-container {
  margin-top: 5rem;
}
.providers-page .page-container .providers-container {
  margin: auto;
  max-width: 1000px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.providers-page .page-container .providers-container h3 {
  color: #151C7E;
  font-size: 14px;
}
.providers-page .page-container .providers-container .provider-card {
  margin: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  filter: drop-shadow(1rem 1rem 3px rgba(0, 0, 0, 0.16));
  width: 10rem;
  height: 7rem;
  transition: 0.3s;
}
.providers-page .page-container .providers-container .provider-card img {
  width: 70%;
}
.providers-page .page-container .providers-container .provider-card:hover {
  transform: scale(1.1);
  filter: drop-shadow(1rem 1rem 3px rgba(0, 0, 0, 0.1));
}

.card-slider-container {
  display: flex;
  align-items: center;
}
.card-slider-container span {
  cursor: pointer;
  background-color: red;
}
.card-slider-container button {
  background-color: white;
  opacity: 0.5;
  border: none;
  box-shadow: 1px 1px 4px black;
  min-width: 3rem;
  font-size: 1.5rem;
  font-weight: 700;
  height: 3rem;
  border-radius: 50%;
  transition: 0.1s;
  color: rgb(5, 35, 88);
  margin: 0 -1rem;
}
.card-slider-container button:hover {
  opacity: 1;
}

.card-slider {
  display: flex;
  gap: 2rem;
  align-items: center;
  padding: 2rem;
  overflow: hidden;
  overflow-x: scroll;
  scroll-behavior: smooth;
}
.card-slider::-webkit-scrollbar {
  display: none;
}
.card-slider .provider-slider-card {
  border-radius: 0.3rem;
  overflow: hidden;
  box-shadow: 0.2rem 0.2rem 8px rgba(0, 0, 0, 0.3);
  min-width: 12rem;
  max-width: 12rem;
  height: 16rem;
}
.card-slider .provider-slider-card .provider-image-box {
  padding: 1rem;
  height: 60%;
  display: flex;
  align-items: center;
}
.card-slider .provider-slider-card .provider-image-box img {
  max-width: 80%;
  margin: auto;
}
.card-slider .provider-slider-card .provider-card-info {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40%;
  padding: 1rem;
  background-color: rgb(44, 44, 110);
}
.card-slider .provider-slider-card .provider-card-info h4 {
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: white;
}

.menu-opt {
  text-decoration: none;
  color: #151C7E;
}

@media screen and (max-width: 840px) {
  .contacts-info {
    display: none;
  }
  .page-container {
    padding: 1rem !important;
  }
  .register-page {
    height: 90vh;
  }
  .logo {
    margin: 0 0.5rem;
  }
  .logo img {
    max-width: 100%;
  }
  .header-top {
    justify-content: flex-end !important;
    padding: 0 0.5rem;
  }
  .register-btn {
    top: 3rem !important;
  }
  .menu {
    display: none !important;
  }
  .menu-drawer {
    display: block !important;
  }
  .menu-drawer span {
    font-size: 1.2rem;
    color: #151C7E;
  }
  .ant-drawer-body p {
    margin: 1.5rem 1rem;
    font-size: 1rem;
    font-weight: 500;
  }
  .about-images img {
    min-width: 80%;
    max-width: 80%;
  }
}/*# sourceMappingURL=global.css.map */